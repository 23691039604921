import { ThreeDots } from "react-loader-spinner";
import bosyStyles from "../styles/Body.module.css";
import React from 'react'

function Loader() {
  return (
    <div className={bosyStyles.loader}>
        <ThreeDots color="#00BFFF" height={80} width={80} />
    </div>
  )
}

export default Loader