import globalStyles from './styles/App.module.css';
import bodyStyles from './styles/Body.module.css';
import React from 'react';
import { useState, useRef } from 'react';
import logo from './assets/logo.png';
import badge from './assets/badge.png';
import Form from './components/Form';
import Loader from './components/Loader';
import html2canvas from 'html2canvas';

function App() {

  const [name, setName] = useState(['FULL','NAME']);
  const [loading, setLoading] = useState(false);

  const printRef = useRef();

  const handleDownload = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL('image/png;base64x');
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = data;
      let fileName = `${name[0]} ${name[1]}.jpg`;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  }

  const uploadToCloud= async () => {
    let url;
    const element = printRef.current;
    const canvas = await html2canvas(element);
    setLoading(true);
    fetch("https://api.cloudinary.com/v1_1/ds7xs3pq1/image/upload", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          file: canvas.toDataURL('image/png;base64'),
          upload_preset: "x33fc4qz",
        }),
      }).then((res)=>res.json())
      .then((res)=>{
        // setLinkedin(res.url);
        url = res.url;
        let urlf = 'https://www.linkedin.com/shareArticle?mini=true&url='+ url + '&title=Share%20Your%20Badge'
        setLoading(false)
        window.open(urlf, '_blank');
      }).catch((err)=>{console.log(err)})
      return "Share to Linkedin";
  }

  return (
    <div className="App">
      {loading ? <Loader /> : null}
      <div className={globalStyles.header}>
        <div>
          <img src={logo} className={globalStyles.logo} alt="Logo" />
        </div>
      </div>
      <div className={globalStyles.body}>
        <div className={bodyStyles.canvas}>
          <div className={bodyStyles.badgeContainer} ref={printRef} >
            <img src={badge} className={bodyStyles.image} alt="Badge" />
            <div className={bodyStyles.overText}>
              {name.map(word => {
                return <div>{word}</div>
              })}
            </div>
          </div>
        </div>
        <div className={bodyStyles.form}>
          <div className={bodyStyles.textHead}>GET YOUR BADGE</div>
          <Form
            changeName={setName}
            downloadHandle={handleDownload}
            upLink={uploadToCloud}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
