import React, { useRef } from 'react'
import styles from '../styles/Form.module.css';
import ConfettiGenerator from 'confetti-js';

function Form({changeName, downloadHandle, upLink}) {
  
  const changeTextValue = (e) => {
    let name = e.target.value;
    name = name.toUpperCase();
    name = name.split(" ")
    changeName(name);
  }  

  const field = useRef()
  const btnRef = useRef()

  const shareButton = () => {
      return <button className={styles.linkedin} ref={btnRef} onClick={async (e) => {
        e.preventDefault()
        btnRef.current.value = "Loading..."
        btnRef.current.value = await upLink()
        field.current.value = ""
      }}>
        
        Share to Linkedin
      </button>;
  }

  const confetti = () => {
    const confettiSettings = { target: 'my-canvas' };
      const confetti = new ConfettiGenerator(confettiSettings);
      confetti.render();
      // alert("Now you can share this link to your linkedin")
      field.current.value = "";
      setTimeout(() => {
        confetti.clear();
      }, 4000);
  }

  return (
    <div className={styles.container}>
        <canvas id="my-canvas" className = {styles.cvs}></canvas>
        <form className={styles.form} onSubmit={(e) => {
          e.target.value = "";
        }} >
            <input ref={field} className={styles.textField} type="text" placeholder="Enter Your Name" onChange={changeTextValue} />
            <div className={styles.buttonTray}>
                <button className={styles.button} type="submit" onClick={(e) => {
                  e.preventDefault();
                  confetti();
                  downloadHandle();
                }}>
                  Download
                </button>
                {shareButton()}
            </div>
        </form>
    </div>
  )
}

// ds7xs3pq1

export default Form